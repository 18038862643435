@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: rgb(243 244 246);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Minecraft";
  src: local("Minecraft"),
   url("./fonts/Minecraft.ttf") format("truetype");
  /* font-weight: bold; */
  }


$bgWidth: 10000px;
.hero-container {
  background: linear-gradient(to right, #1e5799 0%,#2ce0bf 19%,#76dd2c 40%,#dba62b 60%,#e02cbf 83%,#1e5799 100%); 
  background-size: $bgWidth 100%;
  animation: gradient-bg 15s linear infinite ;
}

@keyframes gradient-bg{
  0%{
    background-position-x: 0;
  }
  
  100%{
    background-position-x: $bgWidth;
  }
}

.main-token-tr:first-child td {
  border-top-color: #D1D5DB;
}

.horizontal-sticky-header {
  position: sticky;
  left: 0px;
  z-index: 1;
  // Don't overlap the borders which would make the sticky cells appear different
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 1) 95%, rgba(255, 255, 255, 0) 100%);
}

.submission-element {
  z-index: 10;
}

@media (max-width: 500px) {
  .submission-element {
    left: 10px;
    right: 10px;
    bottom: 10px;
  }
}
@media (min-width: 501px) {
  .submission-element {
    left: unset;
    right: 20px;
    bottom: 20px;
  }
}

@media (max-width: 1023px) {
  .animation-element {
    display: none;
  }
}

@media (max-width: 640px) {
  .hero-dismiss-button {
    border-radius: 0px;
    right: 14px;
    top: 5.5px;
  }
}

@media (min-width: 641px) {
  .hero-dismiss-button {
    border-radius: 16px;
    right: 20px;
    top: 9px;
  }
}

/* Hide the small-screen SVG by default */
.small-screen-svg {
  display: none;
}

/* Media query for screen width 640px or below */
@media (max-width: 640px) {
  /* Hide the large-screen SVG */
  .large-screen-svg {
    display: none;
  }

  /* Show the small-screen SVG */
  .small-screen-svg {
    display: inline;
  }
}

.hero-text-py {
  max-width: 30rem
}

@media (max-width: 640px) {
  .hero-text-py {
    max-width: 20rem
  }
}

.data-header {
  z-index: 9;
}

.table-header-border {
  position: absolute;
  background-color: #e5e7eb;
  height: 1px;
  bottom: 0px;
  right: 0;
  left: 0;
}

.edit-mode-table-parent {
  border-left-width: 1px;
  border-left-color: black;
  border-left-style: solid;
  border-radius: 0px !important;
}

.edit-mode-table {
  border-left-width: 0px !important;
  border-left-color: black;
  border-left-style: solid;
  border-radius: 0px;
}


.text-loading-indicator {
  background: linear-gradient(90deg, #f0f0f0 25%, #f0f0f0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading-animation 2.5s infinite;
  border-radius: 4px;
  width: 100%;
  height: 1em;
}

@keyframes loading-animation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}


@media (min-width: 641px) {
  .rounded-child-responsive {
    -moz-border-radius: 12px; 
    -khtml-border-radius: 12px; 
    -webkit-border-radius: 12px; 
  }
}



// html {
//   height:100%;
// }

// body {
//   margin:0;
// }

.bg {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, #6c3 50%, #09f 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  // position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2 {
  animation-direction:alternate-reverse;
  animation-duration:4s;
}

.bg3 {
  animation-duration:5s;
}

// .content {
//   background-color:rgba(255,255,255,.8);
//   border-radius:.25em;
//   box-shadow:0 0 .25em rgba(0,0,0,.25);
//   box-sizing:border-box;
//   left:50%;
//   padding:10vmin;
//   position:fixed;
//   text-align:center;
//   top:50%;
//   transform:translate(-50%, -50%);
// }

// h1 {
//   font-family:monospace;
// }

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}

























@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(90deg);
  }
  50% {
    transform: rotateY(-90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
.rotate-3d {
  animation: spin 5s infinite linear;
}